<template>
  <div class="column">
    <p class="yogo-input-name" v-if="isShowLabel">{{curLabelName}}</p>
    <el-date-picker
      align="left"
      v-model="modelCode"
      @change="sendCode"
      format="yyyy年MM月dd日"
      value-format="yyyy-MM-dd"
      type="date"
      :placeholder="'选择'+curLabelName"
    ></el-date-picker>
    <i class="btnicon btnimgdate"></i>


  </div>
</template>
<script>
import { inputMixins } from "../mixins";
export default {
  mixins: [inputMixins]
};
</script>